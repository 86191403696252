const number_format = (n) => {
    return new Intl.NumberFormat().format(n)
}

const traffics =  ["20", "40", "60", "100"]
const times =  ["1", "3"]

const countries =  ["DE", "FI", "TR", "NL", "US", "FR", "UA", "GB", "AE"]

const countryIcon = (country) => {
    return <span className={"fi fi-" + country.toLowerCase()}></span>;
}

export {
    number_format,
    traffics,
    countries,
    times,
    countryIcon
}
